


/*===========================
    17.ACTION 2 css 
===========================*/


.action-2-area{
	& .action-overlay{
		background-color: rgba(0, 109, 232,.9);
		padding-top: 40px;
		padding-bottom: 60px;
		& .action-item{
			& span{
				color: $white;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 2px;

			}
			& .title{
				color: $white;
				font-size: 36px;
				@media #{$xs} {
					font-size: 26px;
				}
				@media #{$sm} {
					font-size: 26;
				}

			}
		}
		& .action-support{
			@media #{$xs} {
				display: block !important;
			}
			@media #{$sm} {
				display: flex !important;
			}
			& .action-support-item{
				background: #ff5f6e;
				text-align: center;
				padding: 20px 20px;
				position: relative;
				z-index: 5;
				&:hover {
					background: #5b030c;
					box-shadow: 0 4px 8px rgba(121, 21, 21, 0.3);
				}
				& svg{
					position: absolute;
					top: 8px;
					left: 10px;
					opacity: .08;
					color: $white;
					font-size: 45px;
					z-index: -1;
				}
				& p{
					color: $white;
					font-size: 24px;
				}
				& span{
					color: $white;
					font-weight: 700;
					font-size: 30px;
					@media #{$lg} {
						font-size: 18px;
					}
					@media #{$sm} {
						font-size: 18px;
					}
				}
			}
		}
	}
}
