


/*===========================
    19.CHOOSE css 
===========================*/

.choose-area{
	padding-top: 113px; 
	padding-bottom: 40px;
	background: #f5f7ff;
	z-index: 10;
    position: relative;
	& .section-title{
		& .title{
			padding-bottom: 25px;
			@media #{$lg} {
				font-size: 50px;
			}
			@media #{$md} {
				font-size: 50px;
			}
			&::before{
				display: none;
			}
		}
	}
	& .choose-cat{
		& .choose-cat-item{
			position: relative;
			padding-left: 80px;
			@media #{$xs} {
				padding-left: 60px;
			}
			& .title{
				font-size: 24px;
				@media #{$xs} {
					font-size: 18px;
				}
			}
			& p{
				color: #81a3bb;
				padding-top: 13px;
				padding-right: 80px;
				@media #{$xs} {
					padding-right: 0;
				}
			}
			& i{
				height: 50px;
				width: 50px;
				text-align: center;
				line-height: 50px;
				border-radius: 50%;
				background: $white;
				box-shadow: 0px 4px 8px 0px rgba(160, 194, 255, 0.2);
				font-size: 20px;
				color: $theme-color;
				@include transition(0.3s);
				position: absolute;
				top: -10px;
				left: 0;
				@media #{$xs} {
					top: -15px;
				}
				@media #{$sm} {
					top: -10px;
				}

			}
			&:hover{
				& i{
					background: $theme-color;
					color: $white;
				}
			}
		}
	}
	& .choose-thumb-area{
		position: relative;
		& .choose-thumb-1{
			@media #{$xs} {
				display: none;
			}
		}
		& .choose-thumb-2{
			position: absolute;
			right: 0;
			z-index: 55;
			max-width: 80%;
			@media #{$xs} {
				position: inherit;
				top: 30px;
				display: none;
			}
			&::before{
				position: absolute;
				content: '';
				width: 0;
				height: 0;
				border-bottom: 370px solid $theme-color;
				border-left: 370px solid transparent;
				z-index: -1;
				right: -20px;
				bottom: -20px;
				@media #{$xs} {
					display: none;
				}

			}
		}
	}
	& .choose-video-thumb{
		position: relative;
		@media #{$md} {
			margin-top: 200px;
		}
		@media #{$xs} {
			margin-top: 60px;
		}
		@media #{$sm} {
			margin-top: 200px;
		}
		& a{
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform(translate(-50%, -50%));
			height: 100px;
			width: 100px;
			text-align: center;
			line-height: 100px;
			color: $white;
			border-radius: 50%;
			background: $theme-color;
			&::before{
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				border-radius: 50%;
				border: 2px solid rgba(227, 238, 255,1);
				-webkit-animation: play-btn-1 2.5s linear infinite;
				-moz-animation: play-btn-1 2.5s linear infinite;
				-o-animation: play-btn-1 2.5s linear infinite;
				animation: play-btn-1 2.5s linear infinite;
			}
			&::after{
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				border-radius: 50%;
				border: 2px solid rgba(227, 238, 255,1);
				-webkit-animation: play-btn-2 1.5s linear infinite;
				-moz-animation: play-btn-2 1.5s linear infinite;
				-o-animation: play-btn-2 1.5s linear infinite;
				animation: play-btn-2 1.5s linear infinite;
			}
		}
	}
}

.choose-area-ofsore-tech-team{
	padding-top: 15px; 
	padding-bottom: 15px;
	background: white;
	z-index: 10;
    position: relative;
	& .section-title{
		& .title{
			padding-bottom: 15px;
			@media #{$lg} {
				font-size: 18px;
			}
			@media #{$md} {
				font-size: 18px;
			}
			&::before{
				display: none;
			}
		}
	}
	& .choose-cat{
		& .choose-cat-item{
			position: relative;
			padding-left: 40px;
			@media #{$xs} {
				padding-left: 40px;
			}
			& .title{
				font-size: 16px;
				@media #{$xs} {
					font-size: 12px;
				}
			}
			& p{
				color: #81a3bb;
				padding-top: 12px;
				padding-right: 20px;
				@media #{$xs} {
					padding-right: 0;
				}
			}
			& i{
				height: 20px;
				width: 20px;
				text-align: center;
				line-height: 20px;
				border-radius: 50%;
				background: $white;
				box-shadow: 0px 4px 8px 0px rgba(160, 194, 255, 0.2);
				font-size: 16px;
				color: #636468;
				@include transition(0.3s);
				position: absolute;
				top: 0px;
				left: 0;
				@media #{$xs} {
					top: 0px;
				}
				@media #{$sm} {
					top: 0px;
				}

			}
			&:hover{
				& i{
					background: $theme-color;
					color: $white;
				}
			}
		}
	}
	& .choose-dot{
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
		& img{
			width: 100%;
		}
	}
	& .choose-shape{
		position: absolute;
		left: 0;
		top: -170px;
		z-index: -1;
		& img{
			width: 100%;
		}
	}
}

