

/*===========================
    1. COMMON css 
===========================*/


@import url('https://fonts.googleapis.com/css?family=Karla:400,400i,700,700i&display=swap');
@font-face {
    font-family: 'GilroyBold';
    src: url('../fonts/GilroyBold.eot');
    src: url('../fonts/GilroyBold.eot') format('embedded-opentype'),
         url('../fonts/GilroyBold.woff2') format('woff2'),
         url('../fonts/GilroyBold.woff') format('woff'),
         url('../fonts/GilroyBold.ttf') format('truetype'),
         url('../fonts/GilroyBold.svg#GilroyBold') format('svg');
}


 body {
	font-family: $karla;
	font-weight: normal;
	font-style: normal;
  overflow-x: hidden;
    color: $text-color;
}

*{
    margin: 0;
    padding: 0;
    @include box-sizing (border-box);
}

img {
	max-width: 100%;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
	text-decoration: none;
	outline: none;
}

a:focus,
a:hover{
	text-decoration: none;
}

i,
span,
a{
    display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $gilroy;
	font-weight: 700;
	color: $heading-color;
	margin: 0px;
}

h1 {
	font-size: 48px;
}
h2 {
	font-size: 36px;
}
h3 {
	font-size: 28px;
}
h4 {
	font-size: 22px;
}
h5 {
	font-size: 18px;
}
h6 {
	font-size: 16px;
}
ul,ol {
	margin: 0px;
	padding: 0px;
    list-style-type: none;
}

p {
	font-size: 16px;
	font-weight: 400;
	line-height: 28px;
	color: $text-color;
	margin: 0px;
}

.bg_cover{
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    overflow: hidden;
}


// others common css here



/*===== All Button Style =====*/

.main-btn {
	display: inline-block;
	font-weight: 700;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	@include user-select(none);
	border: 2px solid $theme-color;
	padding: 0 55px;
	font-size: 16px;
	line-height: 60px;
	border-radius: 6px;
    color: $white;
    cursor: pointer;
    z-index: 5;
    @include transition(0.4s);
    background-color: $theme-color;
    
    &:hover{
    	background-color: transparent;
        color: $white;
  		border-color: rgba(255, 255, 255,.2);
    }
    
    &.main-btn-2{
        background-color: transparent;
        color: $white;
        border-color: rgba(255, 255, 255,.2);
        &:hover{
            background-color: $theme-color;
            border-color: $theme-color;
            color: $white;
        }
    }
}





.section-title{
  & span{
    color: $theme-color;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 5px;
    z-index: 6;
    position: relative;
  }
  & .title{
    font-size: 70px;
    font-weight: 700;
    text-transform: capitalize;
    position: relative;
    z-index: 5;
    margin-bottom: 33px;
    @media #{$xs} {
      font-size: 44px;
    }
    @media #{$xs} {
      font-size: 36px;
    }
    @media #{$sm} {
      font-size: 54px;
    }
    &::before{
      position: absolute;
      content: 'services';
      font-size: 180px;
      font-family: $gilroy;
      font-weight: 700;
      bottom: 0;
      color: #f5f6f8;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      @media #{$md} {
        font-size: 145px;
      }
      @media #{$xs} {
        font-size: 60px;
        bottom: 15px;
      }
      @media #{$sm} {
        font-size: 120px;
      }

    }
  }
}

.section-title-2{
  padding-bottom: 45px;
  & .title{
    font-size: 60px;
    margin-top: -2px;
    padding-bottom: 5px;
    @media #{$lg} {
      font-size: 48px;
    }
    @media #{$xs} {
      font-size: 38px;
    }
    @media #{$sm} {
      font-size: 57px;
    }
  }
  & p{
    font-size: 16px;
  }
}

.mt-m2{
  margin-top: -20px;
}



/*--------------------------------------------------------------------------------------
PRELOADER
--------------------------------------------------------------------------------------*/
.preloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $theme-color;
    z-index: 9999999;
    text-align: center
}

.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 40px;
    display: flex;
    align-items: center;
}
.line {
    width: 5px;
    height: 40px;
    background: $white;
    margin: 0 3px;
    border-radius: 10px;
    animation: loading 0.8s infinite;
}
.line:nth-child(2) {
    animation-delay: 0.1s;
}
.line:nth-child(3) {
    animation-delay: 0.2s;
}
.line:nth-child(4) {
    animation-delay: 0.3s;
}
.line:nth-child(5) {
    animation-delay: 0.4s;
}
.line:nth-child(6) {
    animation-delay: 0.5s;
}
.line:nth-child(7) {
    animation-delay: 0.6s;
}
.line:nth-child(8) {
    animation-delay: 0.7s;
}
@keyframes loading {
    0% {
        height: 0;
    }
    50% {
        height: 40px;
    }
    100% {
        height: 0;
    }
}


.breadcrumb-container {
  display: 'flex';
  justify-content: 'center';
};

.breadcrumb-nav {
  margin: '10px 0';
};